export const dashboardJsonCard = {
  exploreModuleText: "Explore Module",
  getMoreOfAiMltext: "Get More of AI.ML",
  footerHeading: "Empowering Builders",
  footerSubHeading: "of the AI Economy.",
  copyRightFooterText: "© 2024, NeevAI SuperCloud Private Limited",
  labeltext: "New",
  exploreData: [
    {
      id: 1,
      title: "Meta Llama 3.3 70B Instruct",
      text: "Llama 3.3 70B is the first openly available model that rivals the top AI models when it comes to state-of-the-art capabilities in general knowledge, steerability, math, tool use, and multilingual translation",
      titleTag: true,
      btnText: "Try Now",
      isFooterShow: true,
      features: [
        {
          icon: "/aiml/edit.svg",
          text: "Text Generation",
        },
        {
          icon: "/aiml/rocket.svg",
          text: "Transformers",
        },
        {
          text: "Llama 3.3 70B",
        },

        {
          icon: "/aiml/earth.svg",
          text: "14 Languages",
        },
        {
          icon: "/aiml/expand.svg",
          text: "Inference Endpoints",
        },
      ],
    },
    {
      id: 2,
      title: "Meta Llama 3 70B",
      text: "Meta Llama 3 70B excels in complex reasoning and generation tasks, with higher accuracy, trained on an extensive collection of text and code.",
      titleTag: false,
      btnText: "Try Now",
      isFooterShow: false,
      features: [
        {
          icon: "/aiml/edit.svg",
          text: "Text Generation",
        },
        {
          icon: "/aiml/rocket.svg",
          text: "Transformers",
        },
        {
          text: "Llama 3 70B",
        },

        {
          icon: "/aiml/earth.svg",
          text: "14 Languages",
        },
        {
          icon: "/aiml/expand.svg",
          text: "Inference Endpoints",
        },
      ],
    },
    {
      id: 3,
      title: "Meta Llama 3 8B",
      text: "Meta Llama 3 8B is a powerful open-source large language model (LLM) optimized for reasoning tasks, with a vast dataset of text and code for high performance.",
      titleTag: false,
      btnText: "Try Now",
      isFooterShow: false,
      features: [
        {
          icon: "/aiml/edit.svg",
          text: "Text Generation",
        },
        {
          icon: "/aiml/rocket.svg",
          text: "Transformers",
        },
        {
          text: "Llama 3 8B",
        },

        {
          icon: "/aiml/earth.svg",
          text: "5 Languages",
        },
        {
          icon: "/aiml/expand.svg",
          text: "Inference Endpoints",
        },
      ],
    },
    {
      id: 4,
      title: "Mixtral 8x7B",
      text: "Mistral 8x7B consists of eight interconnected 7B models, providing enhanced performance and versatility for diverse language tasks through collaborative model architecture",
      titleTag: false,
      btnText: "Try Now",
      isFooterShow: false,
      features: [
        {
          icon: "/aiml/edit.svg",
          text: "Text Generation",
        },
        {
          icon: "/aiml/rocket.svg",
          text: "Transformers",
        },
        {
          text: "Mixtral 8x7B",
        },

        {
          icon: "/aiml/earth.svg",
          text: "8 Languages",
        },
        {
          icon: "/aiml/expand.svg",
          text: "Inference Endpoints",
        },
      ],
    },
  ],
  getMoreData: [
    {
      id: 1,
      title: "Playground",
      text: "Instantly test text generation with an easy-to-use interface. Explore Gen AI effortlessly, no coding needed.",
      icon: "/aiml/walet.svg",
      btnText: "Go to Playground",
      route: "/playground/chat",
      openNewTab: false,
    },
    {
      id: 2,
      title: "Documentation",
      text: "Access a comprehensive knowledge base for AI.ML. Find guides, sample applications, and detailed API references.",
      icon: "/aiml/docs.svg",
      btnText: "Go to Docs",
      route: "https://api.ai.ml/docs/swagger/",
      openNewTab: true,
    },
    {
      id: 3,
      title: "AI.ML Community",
      text: "Join a vibrant community of AI and ML enthusiasts. Connect, share experiences, and collaborate on exciting projects.",
      icon: "/aiml/community.svg",
      btnText: "Join Community",
      route: "https://community.ai.ml/",
      openNewTab: true,
    },
    {
      id: 4,
      title: "Help & Support",
      text: "Get assistance for all your AI.ML needs. Enjoy 24/7 support and personalized help for any issues.",
      icon: "/aiml/chats.svg",
      btnText: "Contact Us",
      route: "",
      openNewTab: false,
    },
  ],
};
