import axios from "axios";
import Cookies from "js-cookie";

const ENV_PROD = true; // For proudction make it true, don't change in the local environment
let minsky_url = "";
if (ENV_PROD) {
  minsky_url = "https://uat.ai.ml";
  // minsky_vectorDB = "https://api.minsky.app/vectordb"
} else {
  minsky_url = "http://127.0.0.1:8000/";
  // minsky_url = "https://103.177.43.219:8085";
  // minsky_vectorDB = "https://uat.minsky.app/vectordb";
}

export const axiosInstance = axios.create({
  baseURL: minsky_url,
  "Content-Type": "application/json",
});
axiosInstance.interceptors.request.use(
  (config) => {
    // if (keyCloakToken) {
    config.headers["Authorization"] = `Bearer ${Cookies.get("keyCloakToken")}`;
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
